import zhLocale from 'element-ui/lib/locale/lang/zh-TW'

export default {
    "intro": {
        "logo": "艾思智創出品",
        "title": "創意自動販賣機 1.0",
        "button": "請投幣",
        "label1": "你已投幣",
        "label2": "機器正在啟動中",
        "note1": "注意 1：推薦在個人電腦上運行，以獲得更好的體驗。",
        "note2": "注意 2：推薦 Chrome 或 Edge 流覽器， 避免使用 Safari 運行。"
    },
    "login": {
        "phone": "用戶名",
        "password": "密碼",
        "login": "登錄"
    },
    "home": {
        "footer": "艾思智創出品",
        "logout": "退出系統",
        "header": "你的創意思路助手",
        "title0": "《Brief 引導模式》",
        "title1": "《方案 brief》",
        "title2": "《方案思路》",
        "title2_1": "《",
        "title2_2": " 方案思路》",
        "title3": "《方案Review檔》",
        "title4": "請選擇  brief  對應的方案類型",
        "title5": "《英文翻譯》",
        "guide00": "你好，我是艾迪，是你的廣告策劃助手！能為你提供各種行銷推廣方案思路，請詳細描述本次的行銷任務，提供與任務相關的資訊，讓我引導您完成方案前的準備工作，生成標準 brief。",
        "guide01": "小提示：請把我當作一位第一天上班的天才策劃，盡可能讓我知道客戶的習慣、偏好、等隱藏資訊，我會更提供你更精准的創意策劃思考。",
        "guide02": "請輸入你的任務描述",
        "guide03": "示例 1：某護膚品即將在2024年12月上市一款護膚精華類產品，功能是抗老，成分是 XXXX，受眾是護膚小白，女性，24-30歲，想嘗試醫美的人群，A醇敏感肌人群，產品定價偏中高端人群，要做市場推廣，主要管道是社交抖音小紅書，有 XXX 明星代言，需要推薦 IP 合作。",
        "guide04": "示例 2：某時尚潮流運動品牌即將上市全新的明星聯名款，和 XXX 明星設計品牌跨界聯名。目標人群是18-40歲，對潮流敏感，XXX 明星的粉絲以及對明星周邊感興趣的人群。產品定價略高於一般產品，但是設計偏前衛，主打城市機能運動風，靈感來自於跑酷等極限運動，中性性別。城市覆蓋一二三線市場。",
        "guide05": "示例 3: 某汽車品牌旗下產品品牌 XXX 在 2024年5月新車型上市，A+級別SUV，主打智慧與同級車型最大空間，面向有一定經濟實力，熱愛生活，傾向於實用和價值投資的家庭消費者。",
        "guide10": "- 以上，是我根據提供的資訊，為你完成的方案 brief 內容，你可以繼續在本對話方塊內完善細節。",
        "guide11_1": "- 你也可以參考《",
        "guide11_2": "》，請點選連結下載 brief 範例。",
        "guide12": "- 請遵照廣告行業的第一性原則 “一個卓越的創意方案，來自一份卓越的 brief”",
        "guide13_1": "- 確認 brief 已提供足夠的資訊與隱含假設後，點擊生成《",
        "guide13_2": "》",
        "guide20": "感謝你的耐心等待，思考方案真是一件不容易的事！接下來，你可以：",
        "guide21": "1. 點擊",
        "button20": "下載檔案",
        "guide23": "，與團隊討論方案，或落成PPT。",
        "guide24": "2. 若改動較大，需要重新調整 brief，請",
        "button21": "返回",
        "guide26": "《方案 brief》",
        "guide27": "對話方塊修改原 brief 內容後，重新生成",
        "guide28": "方案思路",
        "guide29": "3. 你也可以嘗試讓ADGo幫你將方案思路生成方案Review檔，這個格式更適合正式場景 review 方案時使用 。",
        "guide35": "4. 你可以選擇繼續生成另外兩個方案 。",
        "guide30": "注意！若需要人工調整方案思路後生成方案Review檔，建議你",
        "guide31": "1. 先",
        "guide32": "，在本地完成編輯。",
        "guide33": "2. 回到本對話方塊覆蓋原內容，以避免修改過程中內容丟失。",
        "guide34": "確認方案思路調整妥當，生成方案Review檔  》",
        "guide36": "3. 將以上方案思路",
        "button37": "翻譯成英文",
        "button00": "開始生成",
        "waiting1": "艾迪正在思考中，您請先喝杯咖啡，稍等我",
        "waiting2": "分鐘",
        "waiting3": "（前方正在排隊，請稍後）",
        "error01": "很抱歉，艾迪不知道把方案放哪去了，請點擊",
        "errorbutton01": "重新生成",
        "error03": "（系統報錯，將為你重新生成）",
        "error11": "很抱歉，艾迪生病回家了，請緊急聯繫他的老闆“Soun”。",
        "error12": "（系統報錯，請發送資訊：turbo.shen@zero-branding.com）",
        "uploadtitle": "上傳文件",
        "upload01": "拖拽上傳對方案有幫助的文檔： PDF /Word /TEXT，檔大小不能超過10MB。",
        "upload02": "- 品牌介紹<",
        "upload03": "- 產品說明",
        "upload04": "- 消費者報告和資料",
        "upload05": "- 市場報告和資料",
        "upload06": "（功能尚未開放）",
        "errmsg01": "請輸入一句話描述",
        "errmsg02": "下載失敗",
        "modelmessgage01": "請輸入brief名稱",
        "modeltitle01": "修改brief名稱",
        "modelok": "確定",
        "modelcancel": "取消",
        "modelmessgage02": "此操作將永久刪除brief, 是否繼續?",
        "modeltitle02": "刪除對話"
    },
    "sidebar": {
        "dropdown": {
            "course": "我的套餐",
            "team": "團隊",
            "usage": "用量情況",
            "settings": "設置",
            "logout": "退出登錄"
        }
    },
    "dialog": {
        "course": {
            "title": "您的套餐",
            "button": {
                "current": "您的當前套餐",
                "disable": "不可選套餐",
                "upgrade": "升級套餐"
            },
            "preview": {
                "name": "體驗版",
                "price": "近60份的方案/3個月",
                "feature1": "使用當下最新的方案能力",
                "feature2": "3個月內多達近60份的方案",
                "feature3": "多達5名成員共同使用",
            },
            "standard": {
                "name": "正式版",
                "price": "150,000元的額度/12個月",
                "feature1": "12個月內所有版本的方案能力",
                "feature2": "實際達150,000元的額度",
                "feature3": "多達20名成員共同使用",
                "feature4": "艾思智創其他產品的優先免費體驗權",
                "notice": "*價格按年計費，最少2名用戶"
            },
            "footer": "想參與共創計畫？聯繫我們"
        },
        "settings":{
            "title":"設置",
            "general":{
                "username":"用戶名：",
                "email":"郵箱：",
                "locale":"語言環境：",
                "others":"其他團隊成員:",
                "delete":"刪除所有聊天",
                "delete_button":"刪除全部"
            }
        }
    },
    "usage": {
        "title":"用量情況",
        "users": {
            "self": "本人",
            "others": "其他成員"
        },
        "chart": {
            "title1": "月消耗",
            "title2": "總消耗",
            "title3": "消耗/剩餘"
        },
        "table":{
            "title1":"獲取時間",
            "title2":"狀態",
            "title3":"餘額",
            "title4":"過期時間",
            "status1":"過期",
            "status2":"可用"
        },
        "taskTable":{
            "title":"實際使用情況",
            "column1":"功能",
            "column2":"類型",
            "column3":"次數"
        },
        "explain":{
            "expend":"代幣消耗說明",
            "plan":"方案",
            "translation":"翻譯"
        }
    },
    "workspace":{
        "confirm":{
            "title":"確認生成方案",
            "message":"請先確認brief已保存，方案是以保存的那版brief生成的。您確認生成方案嗎？",
            "ok_button":"確定",
            "cancel_button":"取消",
        },
        "save":{
            "success":"保存成功"
        },
        "edit":{
            "start":"修改",
            "finish":"完成修改"
        },



    },
    ...zhLocale
}
