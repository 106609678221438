import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

export default {
    "intro": {
        "logo": "艾思智创出品",
        "title": "创意自动贩卖机 1.0",
        "button": "请投币",
        "label1": "你已投币",
        "label2": "机器正在启动中",
        "note1": "注意 1：推荐在个人电脑上运行，以获得更好的体验。",
        "note2": "注意 2：推荐 Chrome 或 Edge 浏览器， 避免使用 Safari 运行。"
    },
    "login": {
        "phone": "用户名",
        "password": "密码",
        "login": "登录"
    },
    "home": {
        "footer": "艾思智创出品",
        "logout": "退出系统",
        "header": "你的创意思路助手",
        "title0": "《Brief 引导模式》",
        "title1_1": "《",
        "title1_2": " 方案brief》",
        "title2": "《方案思路》",
        "title2_1": "《",
        "title2_2": " 方案思路》",
        "title3": "《方案Review文件》",
        "title4": "请选择  brief  对应的方案类型",
        "title5": "《英文翻译》",
        "guide00": "你好，我是艾迪，是你的广告策划助手！能为你提供各种营销推广方案思路，请详细描述本次的营销任务，提供与任务相关的信息，让我引导您完成方案前的准备工作，生成标准 brief。",
        "guide01": "小提示：请把我当作一位第一天上班的天才策划，尽可能让我知道客户的习惯、偏好、等隐藏信息，我会更提供你更精准的创意策划思考。",
        "guide02": "请输入你的任务描述",
        "guide03": "示例 1：某护肤品即将在2024年12月上市一款护肤精华类产品，功能是抗老，成分是 XXXX，受众是护肤小白，女性，24-30岁，想尝试医美的人群，A醇敏感肌人群，产品定价偏中高端人群，要做市场推广，主要渠道是社交抖音小红书，有 XXX 明星代言，需要推荐 IP 合作。",
        "guide04": "示例 2：某时尚潮流运动品牌即将上市全新的明星联名款，和 XXX 明星设计品牌跨界联名。目标人群是18-40岁，对潮流敏感，XXX 明星的粉丝以及对明星周边感兴趣的人群。产品定价略高于一般产品，但是设计偏前卫，主打城市机能运动风，灵感来自于跑酷等极限运动，中性性别。城市覆盖一二三线市场。",
        "guide05": "示例 3: 某汽车品牌旗下产品品牌 XXX 在 2024年5月新车型上市，A+级别SUV，主打智能与同级车型最大空间，面向有一定经济实力，热爱生活，倾向于实用和价值投资的家庭消费者。",
        "guide10": "- 以上，是我根据提供的信息，为你完成的方案 brief 内容，你可以继续在本对话框内完善细节。",
        "guide11_1": "- 你也可以参考《",
        "guide11_2": "》，请点击链接下载 brief 范例。",
        "guide12_1": "- 请遵照广告行业的第一性原则 ",
        "guide12_2": "“一个卓越的创意方案，来自一份卓越的 brief”",
        "guide13_1": "- 确认 brief 已提供足够的信息与隐含假设后，点击生成《",
        "guide13_2": "》",
        "guide20": "感谢你的耐心等待，思考方案真是一件不容易的事！接下来，你可以：",
        "guide21": "1. 点击",
        "button20": "下载文件",
        "guide23": "，与团队讨论方案，或落成PPT。",
        "guide24": "2. 若改动较大，需要重新调整 brief，请",
        "button21": "返回",
        "guide26": "《方案 brief》",
        "guide27": "对话框修改原 brief 内容后，重新生成",
        "guide28": "方案思路",
        "guide29": "3. 你也可以尝试让ADGo帮你将方案思路生成方案Review文件，这个格式更适合正式场景 review 方案时使用 。",
        "guide35": "4. 你可以选择继续生成另外两个方案 。",
        "guide30": "注意！若需要人工调整方案思路后生成方案Review文件，建议你",
        "guide31": "1. 先",
        "guide32": "，在本地完成编辑。",
        "guide33": "2. 回到本对话框复盖原内容，以避免修改过程中内容丢失。",
        "guide34": "确认方案思路调整妥当，生成方案Review文件  》",
        "guide36": "3. 将以上方案思路",
        "button37": "翻译成英文",
        "button00": "开始生成",
        "waiting1": "艾迪正在思考中，您请先喝杯咖啡，稍等我",
        "waiting2": "分钟",
        "waiting3": "（前方正在排队，请稍后）",
        "error01": "很抱歉，艾迪不知道把方案放哪去了，请点击",
        "errorbutton01": "重新生成",
        "error03": "（系统报错，将为你重新生成）",
        "error11": "很抱歉，艾迪生病回家了，请紧急联系他的老板“Soun”。",
        "error12": "（系统报错，请发送信息：turbo.shen@zero-branding.com）",
        "uploadtitle": "上传文件",
        "upload01": "拖拽上传对方案有帮助的文档： PDF /Word /TEXT，文件大小不能超过10MB。",
        "upload02": "- 品牌介绍<",
        "upload03": "- 产品说明",
        "upload04": "- 消费者报告和数据",
        "upload05": "- 市场报告和数据",
        "upload06": "（功能尚未开放）",
        "errmsg01": "请输入一句话描述",
        "errmsg02": "下载失败",
        "modelmessgage01": "请输入brief名称",
        "modeltitle01": "修改brief名称",
        "modelok": "确定",
        "modelcancel": "取消",
        "modelmessgage02": "此操作将永久删除brief, 是否继续?",
        "modeltitle02": "删除对话"
    },
    "sidebar": {
        "dropdown": {
            "course": "我的套餐",
            "team": "团队",
            "usage": "用量情况",
            "settings": "设置",
            "logout": "退出登录"
        }
    },
    "dialog": {
        "course": {
            "title": "您的套餐",
            "button": {
                "current": "您的当前套餐",
                "disable": "不可选套餐",
                "upgrade": "升级套餐"
            },
            "preview": {
                "name": "体验版",
                "price": "近60份的方案/3个月",
                "feature1": "使用当下最新的方案能力",
                "feature2": "3个月内多达近60份的方案",
                "feature3": "多达5名成员共同使用",
            },
            "standard": {
                "name": "正式版",
                "price": "150,000元的额度/12个月",
                "feature1": "12个月内所有版本的方案能力",
                "feature2": "实际达150,000元的额度",
                "feature3": "多达20名成员共同使用",
                "feature4": "艾思智创其他产品的优先免费体验权",
                "notice": "*价格按年计费，最少2名用户"
            },
            "footer": "想参与共创计划？联系我们"
        },
        "settings": {
            "title": "设置",
            "general": {
                "username": "用户名：",
                "email": "邮箱：",
                "locale": "语言环境：",
                "others": "其他团队成员:",
                "delete": "删除所有聊天",
                "delete_button": "删除全部"
            }
        }
    },
    "usage": {
        "title":"用量情况",
        "users": {
            "self": "本人",
            "others": "其他成员"
        },
        "chart": {
            "title1": "月消耗",
            "title2": "总消耗",
            "title3": "消耗/剩余"
        },
        "table":{
            "title1":"获取时间",
            "title2":"状态",
            "title3":"余额",
            "title4":"过期时间",
            "status1":"过期",
            "status2":"可用"
        },
        "taskTable":{
            "title":"实际使用情况",
            "column1":"功能",
            "column2":"类型",
            "column3":"次数"
        },
        "explain":{
            "expend":"代币消耗说明",
            "plan":"方案",
            "translation":"翻译"
        }
    },
    "workspace":{
        "confirm":{
            "title":"确认生成方案",
            "message":"请先确认brief已保存，方案是以保存的那版brief生成的。您确认生成方案吗？",
            "ok_button":"确定",
            "cancel_button":"取消",
        },
        "save":{
            "success":"保存成功"
        },
        "edit":{
            "start":"修改",
            "finish":"完成修改"
        },
    },
    ...zhLocale
}
