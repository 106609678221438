import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css'
import {format} from 'silly-datetime';
import router from "./router";
import translation from "./utils/translation"
import http from "./utils/http"
import downloader from "./utils/downloader"
import i18n from './lang/index'
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
Vue.use(VMdEditor);

Vue.config.productionTip = false
Vue.use(
    {
        i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
    }
)
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value)});
Vue.prototype.axios = http;
Vue.prototype.downloader = downloader;
Vue.prototype.dateformat = format;
Vue.prototype.translation=translation
new Vue({
    render: h => h(App),
    router,
    i18n
}).$mount('#app')
