import Vue from 'vue'
import VueRouter from 'vue-router';

const Home = () => import("@/components/Home");
const Intro = () => import("@/components/Intro")
const Usage = () => import("@/components/Usage")
const Workspace = () => import("@/components/Workspace")
Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {path: '/', redirect: '/intro'},
    {
        path: '/home',
        component: Home,
        children: [
            {path: 'usage', component: Usage},
            {path: 'workspace', component: Workspace}
        ]
    },
    {path: '/intro', component: Intro}
]
const router = new VueRouter({
    mode: 'history',
    routes: routes
});

export default router;
