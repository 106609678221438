import Axios from "axios";
import router from "../router";
import {Message} from "element-ui";

let axios = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.AUTHORIZATION = token;
    }
    return config;
}, err => {
    return Promise.reject(err);
})

axios.interceptors.response.use(response => {
    switch (response.data.code) {
        case 200:
            return response.data;
        case 401:
        case 403:
            localStorage.removeItem("token")
            router.push('/intro');
            return Promise.reject();
        case 500:
            Message.error(response.data.msg);
            return Promise.reject();
    }
}, err => {
    Message.error("系统故障请稍后再试");
    return Promise.reject(err);
})

export default axios
