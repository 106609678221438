import Vue from "vue"
import VueI18n from "vue-i18n"
import zh_cn from '@/lang/zh-cn'
import zh_tw from  '@/lang/zh-tw'
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh-cn',   //这里 配置默认语言
    messages:{
        'zh-cn':zh_cn,
        'zh-tw':zh_tw
    },
    silentTranslationWarn: true,
    globalInjection: true,
    fallbackLocale: 'zh-cn',
})

export default i18n