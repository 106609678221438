import Axios from "axios";

let downloader = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});
downloader.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.AUTHORIZATION = token;
    }
    return config;
}, err => {
    return Promise.reject(err);
})

export default downloader
